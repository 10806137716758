@charset "UTF-8";
/* colors */
/* font-family */
/* easing */
.catch {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  color: #000;
  transform: translate(-50%, -50%);
}

.catch .box {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 0 0 5px;
}

.catch .box span b {
  text-align: left;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: .1em;
  opacity: 0;
  transition: opacity 2s;
}

.catch .box span b.on {
  opacity: 1;
}

.catch .box span:before,
.catch .box span:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  background-color: #000;
}

.catch .box span:before {
  right: 0;
  width: 0;
  height: 1px;
}

.catch .box span:after {
  left: 0;
  width: 1px;
  height: 0;
}

.catch .box:before,
.catch .box:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-color: #000;
}

.catch .box:before {
  left: 0;
  width: 0;
  height: 1px;
}

.catch .box:after {
  right: 0;
  width: 1px;
  height: 0;
}

.catch .en {
  white-space: nowrap;
  font-size: 18px;
  letter-spacing: .1em;
  position: relative;
  top: 50px;
  opacity: 0;
  transition: all 2s;
}

.catch .en small {
  display: block;
  font-size: 11px;
}

.catch .en.on {
  top: 0;
  opacity: 1;
}

.catch.on .box:before {
  animation: DRAW1 1s linear forwards;
}

.catch.on .box:after {
  animation: DRAW2 1s linear forwards;
}

.catch.on .box span:before {
  animation: DRAW3 1s linear forwards;
}

.catch.on .box span:after {
  animation: DRAW4 1s linear forwards;
}

header {
  position: relative;
  width: 100%;
  height: 100vh;
}

#main-view {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
}

#main-view .images {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

#main-view .images span {
  line-height: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#main-view .images span b {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

#main-view .images span.prev {
  position: absolute;
  z-index: 2;
}

#main-view .images span.next {
  position: relative;
  z-index: 1;
}

#main-view .images:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

#main-view .catch {
  z-index: 4;
  color: #fff;
}

#main-view .catch .box span:before,
#main-view .catch .box span:after {
  background-color: #fff;
}

#main-view .catch .box:before,
#main-view .catch .box:after {
  background-color: #fff;
}

#slick-town-wrap {
  line-height: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 3;
  width: 100%;
  height: auto;
}

#slick-town-wrap:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

#hgroup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

.sec {
  position: relative;
  padding: 10% 0 0;
}

.sec h2 {
  margin: 0 0 10px;
}

.sec .sec-head {
  margin: 0 0 5%;
}

.sec .sec-body {
  position: relative;
  z-index: 1;
}

.sec.sec01 {
  color: #fff;
  background-color: #1469aa;
  padding: 10% 0;
}

.sec.sec01 .sec-body .sec-img.smt {
  margin: 0 0 15px;
}

.sec.sec01 .sec-body .sec-capt h3 {
  color: #fff;
  margin: 0 0 10px;
}

#slick-recommend {
  overflow: hidden;
  padding: 15px 0 0;
}

#slick-recommend article {
  padding: 0 7.5px;
}

#slick-recommend article .art-in {
  position: relative;
}

#slick-recommend article .art-in .art-content {
  overflow: hidden;
}

#slick-recommend article .art-in .art-content .art-img {
  line-height: 0;
}

#slick-recommend article .art-in .art-content .art-img a {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 65%;
}

#slick-recommend article .art-in .art-content .art-img a img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

#slick-recommend article .art-in .art-content .art-img a i {
  position: absolute;
  top: 60%;
  left: 50%;
  z-index: 2;
  color: #fff;
  font-size: 50px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all .4s;
}

#slick-recommend article .art-in .art-content .art-img a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .4s;
}

#slick-recommend article .art-in .art-content .art-img a:hover i {
  opacity: 1;
  top: 50%;
}

#slick-recommend article .art-in .art-content .art-img a:hover:before {
  opacity: 1;
}

#slick-recommend article .art-in .art-content .art-head {
  margin: 10px 0 0;
}

#slick-recommend article .art-in .art-content .art-head h3 {
  font-size: 16px;
  line-height: 1.8;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  margin: 0 0 10px;
}

#slick-recommend article .art-in .art-content .art-head .art-fee {
  font-weight: bold;
}

#slick-recommend article .art-in .art-content .art-head .art-fee strong {
  color: #c00;
  font-size: 2em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1;
}

#slick-recommend article .art-in .art-content .art-head .art-fee-other span {
  display: inline-block;
}

#slick-recommend article .art-in .art-content .art-head .art-fee-other span b {
  font-weight: bold;
}

#slick-recommend article .art-in .art-content .art-head .art-fee-other span:after {
  content: ' ／ ';
}

#slick-recommend article .art-in .art-content .art-head .art-fee-other span:last-of-type:after {
  content: '';
}

#slick-recommend article .art-in .art-content .art-body {
  margin: 10px 0 0;
}

#slick-recommend article .art-in .art-content .art-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 3px;
  line-height: 1.8;
}

#slick-recommend article .art-in .art-content .art-body table tr th {
  position: relative;
  white-space: nowrap;
  font-weight: bold;
  padding: 3px 10px;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.05);
}

#slick-recommend article .art-in .art-content .art-body table tr td {
  padding: 3px 0 3px 15px;
}

#slick-recommend article .art-in .art-content .art-body table tr:first-of-type th {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

#slick-recommend article .art-in .art-content .art-foot {
  margin: 10px 0 0;
}

#slick-recommend article .art-in .art-content .art-foot .art-point {
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.1);
}

#slick-recommend article .art-in:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.slick-list {
  overflow: visible;
}

ul.cat-list {
  line-height: 0;
}

ul.cat-list li .li-in {
  letter-spacing: .1em;
  line-height: 1.8;
  position: relative;
}

ul.cat-list li .li-in .li-img {
  line-height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

ul.cat-list li .li-in .li-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: all .3s;
}

ul.cat-list li .li-in .li-img .searchon {
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 2;
  width: 100%;
  color: #fff;
  line-height: 2;
  transform: translate(-50%, -50%);
}

ul.cat-list li .li-in .li-img .searchon span {
  position: relative;
  left: 0;
  display: inline-block;
  margin: 0 0 0 20px;
  border: 1px solid #fff;
  padding: 1px 15px;
  border-radius: 3px;
  transition: all .3s;
}

ul.cat-list li .li-in .li-img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

ul.cat-list li .li-in .li-capt {
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: #fff;
  margin: -45px 20px 0;
  padding: 15px 10px;
}

ul.cat-list li .li-in .li-capt h3 strong {
  display: block;
  font-size: 16px;
}

ul.cat-list li .li-in .li-capt h3 small {
  color: #9d9d9d;
}

ul.cat-list li .li-in a.permalink {
  z-index: 3;
}

ul.cat-list li .li-in:hover .li-img img {
  filter: blur(4px);
}

ul.cat-list li .li-in:hover .li-img .searchon span {
  left: 10px;
  color: #333;
  background-color: #fff;
}

ul.slick-dots li {
  padding: 0;
  margin: 0 7.5px !important;
}

.slick-dots {
  position: relative;
  margin: 40px 0 0;
}

.slick-dots li button::before {
  color: #ccc;
}

.slick-dots li.slick-active button::before {
  color: #1469aa;
}

.slick-dots li button::before {
  -webkit-text-stroke: 0;
}

.sec.sec05 .inner {
  padding: 5%;
  border: 5px solid #eee;
}

.sec.sec05 .sec-body .arts article {
  position: relative;
  margin: 20px 0 0;
  top: 100px;
  opacity: 0;
  transition: top 1.5s ease, opacity 1.5s ease;
}

.sec.sec05 .sec-body .arts article h4 {
  font-size: 14px;
  text-decoration: underline;
}

.sec.sec05 .sec-body .arts article p {
  margin: 5px 0;
}

.sec.sec05 .sec-body .arts article .art-tag {
  color: #1469aa;
}

.sec.sec05 .sec-body .arts article:first-of-type {
  margin: 0;
}

.sec.sec05 .sec-body .arts article.on {
  top: 0;
  opacity: 1;
}

.sec.sec05 .sec-body .arts article:hover h4 {
  text-decoration: none;
}

.sec.sec06 .sec-body ul li {
  margin: 25px 0 0;
}

.sec.sec06 .sec-body ul li .li-in {
  overflow: hidden;
  position: relative;
}

.sec.sec06 .sec-body ul li .li-in .li-img {
  float: left;
  width: 40%;
}

.sec.sec06 .sec-body ul li .li-in .li-img span {
  overflow: hidden;
  position: relative;
  display: block;
  line-height: 0;
  width: 100%;
  height: 0;
  padding-top: 75%;
}

.sec.sec06 .sec-body ul li .li-in .li-img span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform .3s;
}

.sec.sec06 .sec-body ul li .li-in .li-capt {
  margin-left: calc(40% + 15px);
}

.sec.sec06 .sec-body ul li .li-in .li-capt h4 {
  text-decoration: underline;
}

.sec.sec06 .sec-body ul li .li-in:hover .li-img span img {
  transform: translate(-50%, -50%) scale(1.2);
}

.sec.sec06 .sec-body ul li .li-in:hover .li-capt h4 {
  text-decoration: none;
}

.sec.sec06 .sec-body ul li:first-of-type {
  margin: 0;
}

@media screen and (min-width: 600px) {
  .sec {
    padding: 8% 0 0;
  }
  .sec h2 {
    margin: 0 0 20px;
  }
  .sec .sec-head {
    margin: 0 0 4%;
  }
  .sec.sec01 {
    padding: 8% 0;
  }
}

@media screen and (min-width: 800px) {
  .sec {
    padding: 6% 0 0;
  }
  .sec h2 {
    margin: 0 0 20px;
  }
  .sec .sec-head {
    margin: 0 0 3%;
  }
  .sec.sec01 {
    padding: 0;
  }
  .sec.sec01 .sec-body {
    letter-spacing: -.4em;
    position: relative;
  }
  .sec.sec01 .sec-body .sec-capt,
  .sec.sec01 .sec-body .sec-img.pc {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
  }
  .sec.sec01 .sec-body .sec-capt {
    width: 58.5%;
    padding: 6% 5% 6% 6.25%;
  }
  .sec.sec01 .sec-body .sec-capt .btns {
    text-align: left;
  }
  .sec.sec01 .sec-body .sec-img-wrap {
    position: absolute;
    top: 50%;
    left: 58.5%;
    width: 37.5%;
    height: 100%;
    transform: translateY(-50%);
  }
  .sec.sec01 .sec-body .sec-img-wrap .sec-img {
    width: 100%;
    height: 100%;
  }
  .sec.sec01 .sec-body .sec-img-wrap .sec-img span {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .sec.sec01 .sec-body .sec-img-wrap .sec-img span img {
    position: absolute;
    top: 16%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translateX(-50%);
  }
  #slick-recommend article {
    padding: 0 10px;
  }
  .sec.sec05 .inner {
    padding: 0;
    border: 0;
  }
  .sec.sec05 .sec-body .in {
    overflow: hidden;
    padding: 5%;
    border: 5px solid #eee;
  }
  .sec.sec05 .sec-body .in h2 {
    float: left;
  }
  .sec.sec05 .sec-body .arts {
    margin-left: 30.5%;
  }
  .sec.sec05 .sec-body .arts article {
    padding: 0 0 0 20px;
  }
  .sec.sec05 .sec-body .arts article:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #eee;
  }
  .sec.sec05 .sec-body .arts article:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 0;
    background-color: #000;
    transition: height .3s;
  }
  .sec.sec05 .sec-body .arts article:hover:after {
    height: 100%;
  }
  .sec.sec06 .sec-body ul {
    margin: 0 -3.2%;
  }
  .sec.sec06 .sec-body ul li,
  .sec.sec06 .sec-body ul li:first-of-type {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    margin: 0;
    padding: 0 3.2%;
  }
  .sec.sec06 .sec-body ul li .li-in .li-img,
  .sec.sec06 .sec-body ul li:first-of-type .li-in .li-img {
    float: none;
    width: 100%;
  }
  .sec.sec06 .sec-body ul li .li-in .li-capt,
  .sec.sec06 .sec-body ul li:first-of-type .li-in .li-capt {
    position: relative;
    margin: 15px 0 0;
    padding: 0 0 0 20px;
  }
  .sec.sec06 .sec-body ul li .li-in .li-capt:before,
  .sec.sec06 .sec-body ul li:first-of-type .li-in .li-capt:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #eee;
  }
  .sec.sec06 .sec-body ul li .li-in .li-capt:after,
  .sec.sec06 .sec-body ul li:first-of-type .li-in .li-capt:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 5px;
    height: 0;
    background-color: #000;
    transition: height .3s;
  }
  .sec.sec06 .sec-body ul li .li-in:hover .li-capt:after,
  .sec.sec06 .sec-body ul li:first-of-type .li-in:hover .li-capt:after {
    height: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .catch .box {
    width: 120px;
    height: 120px;
  }
  .catch .box span b {
    font-size: 24px;
  }
  .catch .en {
    font-size: 24px;
  }
  .catch .en small {
    font-size: 12px;
  }
  ul.cat-list li {
    display: inline-block;
    vertical-align: top;
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .sec {
    padding: 70px 0 0;
  }
  .sec h2 {
    margin: 0 0 30px;
  }
  .sec .sec-head {
    margin: 0 0 40px;
  }
  #slick-recommend article {
    padding: 0 15px;
  }
}
