
$baseColor: #1469aa; // blue rgba(20,105,170)

$textColor: #333;
$fontSize: 13px;
$lineHeight: 2;


/* colors */

$colorNavy: #100964; // ネイビー

$colorOrange: #ED6103; // オレンジ

$colorCyan: #00bfff; // シアン

$colorRent: #009788; // teal 借りる

$colorSale: #008446; // green 買う

// $colorNews: #505596; // purple お知らせ

// $colorBlog: #0083ad; // blue スタッフブログ


/* font-family */
$fontBase: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
$fontJa: 'Noto Sans Japanese', serif;
$fontRobot: 'Roboto', sans-serif;
$fontRobotCond: 'Roboto Condensed', sans-serif;



/* easing */
@mixin easeOutExpo {
	transition: all .5s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
@mixin easeOutExpo_1s {
	transition: all 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
@mixin easeOutExpo_2s {
	transition: all 2s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeInExpo {
	transition: all .5s cubic-bezier(0.950, 0.050, 0.795, 0.035);
	transition-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
}

@mixin easeOutBack {
	transition: all .5s cubic-bezier(0.175, 0.885, 0.305, 1.650);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.305, 1.650);
}

@mixin easeInOutExpo {
	transition: all .5s cubic-bezier(1.000, 0.000, 0.000, 1.000);
	transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
}