@charset "utf-8";

@import "variables";



.catch{
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: auto;
	color: #000;
	transform: translate(-50%, -50%);
	.box{
		position: relative;
		display: inline-block;
		width: 100px;
		height: 100px;
		margin: 0 0 5px;
		span{
			b{
				text-align: left;
				position: absolute;
				bottom: 10px;
				left: 10px;
				font-size: 20px;
				line-height: 1.4;
				// font-weight: lighter;
				letter-spacing: .1em;
				opacity: 0;
				transition: opacity 2s;
			}
			b.on{
				opacity: 1;
			}
		}
		span:before,
		span:after{
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			background-color: #000;
		}
		span:before{
			right: 0;
			width: 0;
			height: 1px;
		}
		span:after{
			left: 0;
			width: 1px;
			height: 0;
		}
	}
	.box:before,
	.box:after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		background-color: #000;
	}
	.box:before{
		left: 0;
		width: 0;
		height: 1px;
	}
	.box:after{
		right: 0;
		width: 1px;
		height: 0;
	}
	
	.en{
		white-space: nowrap;
		font-size: 18px;
		letter-spacing: .1em;
		position: relative;
		top: 50px;
		opacity: 0;
		transition: all 2s;
		small{
			display: block;
			font-size: 11px;
		}
	}
	.en.on{
		top: 0;
		opacity: 1;
	}
}
.catch.on{
	.box:before{
		animation: DRAW1 1s linear forwards;
	}
	.box:after{
		animation: DRAW2 1s linear forwards;
	}
	.box{
		span:before{
			animation: DRAW3 1s linear forwards;
		}
		span:after{
			animation: DRAW4 1s linear forwards;
		}
	}
}

header{
	// overflow: hidden;
	position: relative;
	width: 100%;
	height: 100vh;
}

#main-view{
	position: relative;
	width: 100%;
	height: 100%;
	padding: 10px;
	background-color: #fff;
	.images{
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 100%;
		span{
			line-height: 0;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			b{
				display: block;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				position: relative;
				// left: -10%;
				// transition: all 10s linear;
			}
			
		}
		span.prev{
			position: absolute;
			z-index: 2;
			b{
				// left: 0;
			}
		}
		span.next{
			position: relative;
			z-index: 1;
			b{
				// left: 0;
			}
		}
	}
	.images:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
	}

	.catch{
		z-index: 4;
		color: #fff;
		.box{
			span:before,
			span:after{
				background-color: #fff;
			}
		}
		.box:before,
		.box:after{
			background-color: #fff;
		}
	}
}

#slick-town-wrap{
	line-height: 0;
	position: absolute;
	bottom: 10px;
	left: 0;
	z-index: 3;
	width: 100%;
	height: auto;
}
#slick-town-wrap:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

#hgroup{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	.hgroup-in{
		// padding: 0 10px;
	}
}


.sec{
	position: relative;
	padding: 10% 0 0;
	h2{
		margin: 0 0 10px;
	}
	.sec-head{
		margin: 0 0 5%;
	}
	.sec-body{
		position: relative;
		z-index: 1;
	}
}

.sec.sec01{
	color: #fff;
	background-color: $baseColor;
	padding: 10% 0;
	.sec-body{
		.sec-img.smt{
			margin: 0 0 15px;
		}
		.sec-capt{
			h3{
				color: #fff;
				margin: 0 0 10px;
			}
		}
	}
}

.sec.sec02{
	
}
#slick-recommend{
	overflow: hidden;
	padding: 15px 0 0;
	article{
		padding: 0 7.5px;
		.art-in{
			position: relative;
			// background-color: #fff;			
			.art-content{
				overflow: hidden;
				.art-img{
					line-height: 0;
					a{
						display: block;
						overflow: hidden;
						position: relative;
						width: 100%;
						height: 0;
						padding-top: 65%;
						img{
							position: absolute;
							top: 50%;
							left: 50%;
							width: 100%;
							height: auto;
							transform: translate(-50%, -50%);
						}
						i{
							position: absolute;
							top: 60%;
							left: 50%;
							z-index: 2;
							color: #fff;
							font-size: 50px;
							opacity: 0;
							transform: translate(-50%, -50%);
							transition: all .4s;
						}
					}
					a:before{
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						width: 100%;
						height: 100%;
						background-color: rgba(0,0,0,.5);
						opacity: 0;
						transition: opacity .4s;
					}
					a:hover{
						i{
							opacity: 1;
							top: 50%;
						}
					}
					a:hover:before{
						opacity: 1;
					}
				}
				.art-head{
					margin: 10px 0 0;
					h3{
						// color: $baseColor;
						font-size: 16px;
						line-height: 1.8;
						font-family: $fontJa;
						font-weight: 600;
						margin: 0 0 10px;
					}
					.art-fee{
						font-weight: bold;
						strong{
							color: #c00;
							font-size: 2em;
							font-family: $fontRobot;
							font-weight: 700;
							line-height: 1;
						}
					}
					.art-fee-other{
						span{
							display: inline-block;
							// margin: 0 10px 0 0;
							b{
								font-weight: bold;
							}
						}
						span:after{
							content: ' ／ ';
						}
						span:last-of-type:after{
							content: '';
						}
					}
				}
				.art-body{
					margin: 10px 0 0;
					table{
						width: 100%;
						border-collapse: separate;
						border-spacing: 0 3px;
						line-height: 1.8;
						tr{
							th{
								position: relative;
								white-space: nowrap;
								font-weight: bold;
								padding: 3px 10px;
								text-align: center;
								vertical-align: middle;
								// background-color: rgba(20,105,170,.1);
								background-color: rgba(0,0,0,.05);
							}
							td{
								padding: 3px 0 3px 15px;
							}
						}
						tr:first-of-type{
							th{
								color: #fff;
								// background-color: $baseColor;
								background-color: rgba(0,0,0,.4);
							}
						}
					}
				}
				.art-foot{
					margin: 10px 0 0;
					.art-point{
						padding: 10px;
						background-color: rgba(255,0,0,.1);
					}
				}
			}
		}
		.art-in:hover{
			box-shadow: 0 0 0 rgba(0,0,0,0.2);
		}
	}
	article:first-of-type{
		// margin: 0;
	}
}

.slick-list{
	overflow: visible;
}


.sec.sec03,
.sec.sec04{

}
ul.cat-list{
	line-height: 0;
	li{
		.li-in{
			letter-spacing: .1em;
			line-height: 1.8;
			position: relative;
			.li-img{
				line-height: 0;
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 100%;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 150%;
					height: auto;
					transform: translate(-50%, -50%);
					transition: all .3s;
				}
				.searchon{
					text-align: center;
					position: absolute;
					top: 45%;
					left: 50%;
					z-index: 2;
					width: 100%;
					color: #fff;
					line-height: $lineHeight;
					transform: translate(-50%, -50%);
					span{
						position: relative;
						left: 0;
						display: inline-block;
						margin: 0 0 0 20px;
						border: 1px solid #fff;
						padding: 1px 15px;
						border-radius: 3px;
						transition: all .3s;
					}
				}
			}
			.li-img:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.3);
			}
			.li-capt{
				text-align: center;
				position: relative;
				z-index: 1;
				background-color: #fff;
				margin: -45px 20px 0;
				padding: 15px 10px;
				h3{
					strong{
						display: block;
						font-size: 16px;
					}
					small{
						color: #9d9d9d;
					}
				}	
			}
			a.permalink{
				z-index: 3;
			}
		}
		.li-in:hover{
			.li-img{
				img{
					filter: blur(4px);
				}
				.searchon{
					span{
						left: 10px;
						color: #333;
						background-color: #fff;
					}
				}
			}
		}
	}
	
}

ul.slick-dots{
	li{
		padding: 0;
		margin: 0 7.5px !important;
	}
}

.slick-dots{
	position: relative;
	margin: 40px 0 0;
	li button::before {
		color: #ccc;
	}
	li.slick-active button::before{
		color: $baseColor;
	}
}
.slick-dots li button::before{
	-webkit-text-stroke: 0;
}

.sec.sec05{
	.inner{
		padding: 5%;
		border: 5px solid #eee;
	}
	.sec-body{
		.arts{
			article{
				position: relative;
				margin: 20px 0 0;
				top: 100px;
				opacity: 0;
				transition: top 1.5s ease,
							opacity 1.5s ease;
				h4{
					font-size: 14px;
					text-decoration: underline;
				}
				p{
					margin: 5px 0;
				}
				.art-tag{
					color: $baseColor;
				}
			}
			article:first-of-type{
				margin: 0;
			}
			article.on{
				top: 0;
				opacity: 1;
			}
			article:hover{
				h4{
					text-decoration: none;
				}
			}
		}
	}
}

.sec.sec06{
	.sec-body{
		ul{
			li{
				margin: 25px 0 0;
				.li-in{
					overflow: hidden;
					position: relative;
					.li-img{
						float: left;	
						width: 40%;
						span{
							overflow: hidden;
							position: relative;
							display: block;
							line-height: 0;
							width: 100%;
							height: 0;
							padding-top: 75%;
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								width: 120%;
								height: auto;
								transform: translate(-50%, -50%);
								transition: transform .3s;
							}
						}
					}
					.li-capt{
						margin-left: calc(40% + 15px);
						h4{
							text-decoration: underline;
						}
					}
				}
				.li-in:hover{
					.li-img{
						span{
							img{
								transform: translate(-50%, -50%) scale(1.2);
							}
						}
					}
					.li-capt{
						h4{
							text-decoration: none;
						}
					}
				}
			}
			li:first-of-type{
				margin: 0;
			}
		}
	}
}


@media screen and (min-width: 600px) {
	
	.sec{
		padding: 8% 0 0;
		h2{
			margin: 0 0 20px;
		}
		.sec-head{
			margin: 0 0 4%;
		}
	}

	.sec.sec01{
		padding: 8% 0;
	}

}


@media screen and (min-width: 800px) {

	.sec{
		padding: 6% 0 0;
		h2{
			margin: 0 0 20px;
		}
		.sec-head{
			margin: 0 0 3%;
		}
	}

	.sec.sec01{
		padding: 0;
		.sec-body{
			letter-spacing: -.4em;
			position: relative;
			.sec-capt,
			.sec-img.pc{
				letter-spacing: normal;
				display: inline-block;
				vertical-align: top;
			}
			.sec-capt{
				width: 58.5%;
				padding: 6% 5% 6% 6.25%;
				.btns{
					text-align: left;
				}
			}
			.sec-img-wrap{
				position: absolute;
				top: 50%;
				left: 58.5%;
				width: 37.5%;
				height: 100%;
				transform: translateY(-50%);
				.sec-img{
					width: 100%;
					height: 100%;
					span{
						display: block;
						overflow: hidden;
						position: relative;
						width: 100%;
						height: 100%;
						img{
							position: absolute;
							top: 16%;
							left: 50%;
							width: 100%;
							height: auto;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}

	.sec.sec02{

	}
	#slick-recommend{
		article{
			padding: 0 10px;
		}
	}

	.sec.sec05{
		.inner{
			padding: 0;
			border: 0;
		}
		.sec-body{
			.in{
				overflow: hidden;
				padding: 5%;
				border: 5px solid #eee;
				h2{
					float: left;
				}
			}
			.arts{
				margin-left: 30.5%;
				article{
					padding: 0 0 0 20px;
				}
				article:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 5px;
					height: 100%;
					background-color: #eee;
				}
				article:after{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 5px;
					height: 0;
					background-color: #000;
					transition: height .3s;
				}
				article:hover:after{
					height: 100%;
				}
			}
		}
	}

	.sec.sec06{
		.sec-body{
			ul{
				margin: 0 -3.2%;
				li,
				li:first-of-type{
					display: inline-block;
					vertical-align: top;
					width: 33.3%;
					margin: 0;
					padding: 0 3.2%;
					.li-in{
						
						.li-img{
							float: none;
							width: 100%;
						}
						.li-capt{
							position: relative;
							margin: 15px 0 0;
							padding: 0 0 0 20px;
						}
						.li-capt:before{
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 5px;
							height: 100%;
							background-color: #eee;
						}
						.li-capt:after{
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 1;
							width: 5px;
							height: 0;
							background-color: #000;
							transition: height .3s;
						}
					}
					.li-in:hover{
						.li-capt:after{
							height: 100%;
						}
					}
				}
			}
		}
	}

}


@media screen and (min-width: 1000px) {
	.catch{
		.box{
			width: 120px;
			height: 120px;
			span{
				b{
					font-size: 24px;
				}
			}
		}
		.en{
			font-size: 24px;
			small{
				font-size: 12px;
			}
		}
	}

	.sec.sec02{

	}

	.sec.sec03{

	}
	.sec.sec04{

	}

	ul.cat-list{
		// margin: 0 -10px;
		li{
			display: inline-block;
			vertical-align: top;
			width: 25%;
		}
	}

}


@media screen and (min-width: 1200px) {
	.sec{
		padding: 70px 0 0;
		h2{
			margin: 0 0 30px;
		}
		.sec-head{
			margin: 0 0 40px;
		}
	}

	.sec.sec02{

	}
	#slick-recommend{
		article{
			padding: 0 15px;
			.art-in{	
				.art-content{
					.art-head{
						h3{
							// font-size: 18px;
						}
					}
				}
			}
		}
	}

}

